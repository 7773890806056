<template>
  <div class="container">
    <h1 class="header">{{ $t('home.title') }}</h1>
    <p class="paragraph">
      {{ $t('home.caption') }}
    </p>
    <div class="button-container">
      <PassportButton :label="$t('home.scanButton')" @click="$router.push('/scanner')" class="button" />
    </div>
    <h2 class="stat-header">{{ $t('home.yourStats') }}</h2>
    <div class="box">
      <span class="stats"> {{ points }} </span>
      <span class="stat-description"> {{ $t('home.table.pointsEarned') }}</span>
    </div>
    <div class="box">
      <span class="stats"> {{ totalCompletedTargets }} / {{ totalNumberOfTargets }} </span>
      <span class="stat-description"> {{ $t('home.table.easterEggsFound') }}</span>
    </div>
    <div class="box">
      <span class="stats"> {{ displayRank }} </span>
      <span class="stat-description"> {{ $t('home.table.placeOnLeaderboard') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PassportButton from '@/components/PassportButton.vue';

export default {
  name: 'Home',
  components: {
    PassportButton,
  },
  computed: {
    ...mapState(['uuid', 'username', 'points', 'rank', 'totalCompletedTargets', 'totalNumberOfTargets']),

    displayRank() {
      const j = this.rank % 10;
      const k = this.rank % 100;

      if (j === 1 && k !== 11) {
        return `${this.rank}st`;
      }
      if (j === 2 && k !== 12) {
        return `${this.rank}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${this.rank}rd`;
      }
      return `${this.rank}th`;
    },
  },
  mounted() {
    this.refreshPoints();
  },
  methods: {
    ...mapActions(['refreshPoints']),
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.85px;
}
.stat-header {
  margin-top: 0;
  margin-bottom: 18px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 20px 29px 20px 25px;
  margin: auto;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  @include bp-sm-phone-landscape {
    width: 300px;
  }
}
.stats {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #d9232d;
  text-align: left;
}
.stat-description {
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.paragraph {
  padding: 22px;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 157.143% */
  color: #222;
  text-align: center;
}
.button {
  margin-bottom: 64px;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
</style>
